import axios from '@/axios.js'

const state = () => ({
    kekerasan_moduss: []
})

const mutations = {
    SET_KEKERASANMODUSS (state, payload) {
      state.kekerasan_moduss = payload
    },
    ADD_KEKERASANMODUS (state, item) {
      state.kekerasan_moduss.unshift(item)
    },
    UPDATE_KEKERASANMODUS (state, kekerasan_modus) {
      const kekerasan_modusIndex = state.kekerasan_moduss.findIndex((p) => p.id === kekerasan_modus.id)
      Object.assign(state.kekerasan_moduss[kekerasan_modusIndex], kekerasan_modus)
    },
    REMOVE_KEKERASANMODUS (state, itemId) {
      const ItemIndex = state.kekerasan_moduss.findIndex((p) => p.id === itemId)
      state.kekerasan_moduss.splice(ItemIndex, 1)
    },
}

const actions = {
  fetchKekerasanModus ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`/term-list/kekerasan_modus`)
        .then((response) => {
          commit('SET_KEKERASANMODUSS', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  addKekerasanModus ({ commit }, kekerasan_modus ) {
    return new Promise((resolve, reject) => {
      axios.post('/term-add', kekerasan_modus)
        .then((response) => {
          commit('ADD_KEKERASANMODUS', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateKekerasanModus ({ commit }, kekerasan_modus) {
    return new Promise((resolve, reject) => {
      axios.post(`/term-update/${kekerasan_modus.id}`, kekerasan_modus)
        .then((response) => {
            commit('UPDATE_KEKERASANMODUS', response.data.data)
            resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateFormKekerasanModus ({ commit }, kekerasan_modus) {
    return new Promise((resolve, reject) => {
      axios.post(`/term-update-form`, kekerasan_modus)
        .then((response) => {
            commit('UPDATE_KEKERASANMODUS', response.data.data)
            resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  removeKekerasanModus ({ commit }, kekerasan_modus) {
    return new Promise((resolve, reject) => {
      axios.delete(`/term-delete/${kekerasan_modus.id}`)
        .then((response) => {
            commit('REMOVE_KEKERASANMODUS', kekerasan_modus.id)
            resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  }
}

export default {
  isRegistered: false,
  namespaced: true,
  state,
  actions,
  mutations
}